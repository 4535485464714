import { useEffect, useState } from 'react';
import { FaBehanceSquare } from 'react-icons/fa';
import { Loader } from '../../components/Loader/Loader';
import DissentioCover from '../../assets/dissentio-cover.png';
import MayaComponentsCover from '../../assets/maya-components-cover.png';
import PageIntro from '../../components/PageIntro/PageIntro';
import Button from '../../components/Button/Button';

import './Projects.scss';
import { Link } from 'react-router-dom';

export default function Projects() {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1500);
	}, []);

	return loading ? (
		<Loader />
	) : (
		<section className='projects' id='projects'>
			<PageIntro
				title='projects.'
				description="Where have I put my skills into practice? Explore this section to discover a selection of projects where I've applied my abilities and design mindset."
			/>

			<div className='projects__wrapper'>
				<div className='projects__wrapper__item'>
					<img
						src={DissentioCover}
						alt='Proyecto'
						className='projects__wrapper__item__img'
					/>

					<h3 className='projects__wrapper__item__title'>
						Dissentio case study
					</h3>

					<h5 className='projects__wrapper__item__subtitle'>2024</h5>

					<p className='projects__wrapper__item__description'>
						Dissentio is a reservation app designed for a restaurant.
						In this case study, I've applied my expertise in UX
						research and interface design using Figma.
					</p>

					<a
						href='https://www.behance.net/gallery/190940569/Restaurant-Table-Reservation-App-(UX-Case-Study)?tracking_source=project_owner_other_projects'
						target='_blank'
						rel='noreferrer'
						className='projects__wrapper__item__link'
					>
						<FaBehanceSquare className='projects__wrapper__item__link__icon' />
						Explore the full project
					</a>
				</div>

				<div className='projects__wrapper__item'>
					<img
						src={MayaComponentsCover}
						alt='Proyecto'
						className='projects__wrapper__item__img'
					/>

					<h3 className='projects__wrapper__item__title'>
						Maya Components UI Kit
					</h3>

					<h5 className='projects__wrapper__item__subtitle'>2024</h5>

					<p className='projects__wrapper__item__description'>
						Maya Components is a Figma library crafted to enhance my
						proficiency with this tool and understand the proper way
						to create a design system.
					</p>

					<a
						href='https://www.behance.net/gallery/199004521/Figma-Design-System-(UI-Kit)'
						target='_blank'
						rel='noreferrer'
						className='projects__wrapper__item__link'
					>
						<FaBehanceSquare className='projects__wrapper__item__link__icon' />
						Explore the full project
					</a>
				</div>
			</div>
		</section>
	);
}
