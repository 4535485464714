import { useEffect, useState } from 'react';
import { experienceData, trainingData } from './data/data';
import { Loader } from '../../components/Loader/Loader';
import { IoMdBriefcase } from 'react-icons/io';
import { FaLocationDot } from 'react-icons/fa6';
import PageIntro from '../../components/PageIntro/PageIntro';

import './Experience.scss';

export default function Experience() {
	const [loading, setLoading] = useState(true);
	const [activeTab, setActiveTab] = useState('experience');

	const handleTabChange = (tab) => {
		setActiveTab(tab);
	};

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1500);
	}, []);

	return loading ? (
		<Loader />
	) : (
		<section className='experience' id='experience'>
			<PageIntro
				title='experience.'
				description='Where have I developed my skills? What types of projects have I been involved in? Check out this section to explore my professional and educational journey.'
			/>

			<div className='experience__tabs'>
				<button
					className={`experience__tabs__tab ${
						activeTab === 'experience'
							? 'experience__tabs__tab--active'
							: ''
					}`}
					onClick={() => handleTabChange('experience')}
				>
					work.
				</button>
				<button
					className={`experience__tabs__tab ${
						activeTab === 'training'
							? 'experience__tabs__tab--active'
							: ''
					}`}
					onClick={() => handleTabChange('training')}
				>
					training.
				</button>
			</div>

			<div className='experience__container'>
				{activeTab === 'experience' && (
					<div className='experience__cards'>
						{experienceData.map((item, index) => (
							<div className='experience__cards__item'>
								<div className='experience__cards__item__logo'>
									<img src={item.img} alt={item.alt} />
								</div>
								<div className='experience__cards__item__content'>
									{item.date && (
										<h4 className='experience__cards__item__content__date'>
											{item.date}
										</h4>
									)}

									<h2 className='experience__cards__item__content__title'>
										{item.title}
									</h2>

									<div
										style={{
											display: 'flex',
											gap: '24px',
										}}
									>
										{item.company && (
											<p className='experience__cards__item__content__company'>
												<IoMdBriefcase />
												{item.company}
											</p>
										)}
										{item.location && (
											<p className='experience__cards__item__content__location'>
												<FaLocationDot />
												{item.location}
											</p>
										)}
									</div>
									<p className='experience__cards__item__content__description'>
										{item.description}
									</p>
								</div>
							</div>
						))}
					</div>
				)}
			</div>

			<div className='experience__container'>
				{activeTab === 'training' && (
					<div className='experience__cards'>
						{trainingData.map((item, index) => (
							<div className='experience__cards__item'>
								<div className='experience__cards__item__logo'>
									<img src={item.img} alt={item.alt} />
								</div>
								<div className='experience__cards__item__content'>
									{item.date && (
										<h4 className='experience__cards__item__content__date'>
											{item.date}
										</h4>
									)}

									<h2 className='experience__cards__item__content__title'>
										{item.title}
									</h2>

									<div
										style={{
											display: 'flex',
											gap: '24px',
										}}
									>
										{item.company && (
											<p className='experience__cards__item__content__company'>
												<IoMdBriefcase />
												{item.company}
											</p>
										)}
										{item.location && (
											<p className='experience__cards__item__content__location'>
												<FaLocationDot />
												{item.location}
											</p>
										)}
									</div>
									<p className='experience__cards__item__content__description'>
										{item.description}
									</p>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		</section>
	);
}
