import { useEffect, useState } from 'react';
import ProfilePic from '../../assets/me-rounded.png';
import { FiArrowDownRight } from 'react-icons/fi';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import './About.scss';
import { Loader } from '../../components/Loader/Loader';
import {
	designSkillsData,
	devSkillsData,
	languagesData,
	softSkillsData,
} from './data/data';
import PageIntro from '../../components/PageIntro/PageIntro';

export default function About() {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1500);
	}, []);

	return loading ? (
		<Loader />
	) : (
		<section className='about' id='about'>
			<PageIntro
				title='about.'
				description='Who am I? Where do I live? What are my skills? What do I
					enjoy doing in my free time? This section is created to
					answer all those questions. Take a look!'
			/>
			<div className='about__profile'>
				<div className='about__profile__profile-pic'>
					<img src={ProfilePic} alt='' />
				</div>

				<div className='about__profile__profile-text'>
					<div className='about__profile__profile-text__title'>
						hi, guille{' '}
						<span className='about__profile__profile-text__title--outlined'>
							her
							<span className='about__profile__profile-text__title--outlined--reverse'>
								e
							</span>
							.
						</span>
					</div>

					<div className='about__profile__profile-text__description'>
						I'm a UX/UI Designer and Frontend Developer based in A
						Coruña, Galicia with a passion for creating solutions that
						simplify user tasks. I particularly enjoy working on
						visual design with Figma and CSS, transforming ideas into
						beautiful and user-friendly products. Beyond technology, I
						love traveling, taking walks in nature, plants and
						everything related to art and music. I play traditional
						galician percussion and I'm now learning to play the
						piano. In the future, I would like to learn music
						production.
					</div>
				</div>
			</div>

			<div className='about__skills'>
				<div className='about__skills__skills-container'>
					<h2 className='about__skills__skills-container__title'>
						<FiArrowDownRight className='about__skills__skills-container__title__icon' />
						<span className='about__skills__skills-container__title__text'>
							design skills & tools.
						</span>
					</h2>
					{designSkillsData.map((item) => {
						return (
							<ProgressBar
								key={item.id}
								icon={item.icon}
								label={item.label}
								percentage={item.percentage}
							/>
						);
					})}
				</div>

				<div className='about__skills__skills-container'>
					<h2 className='about__skills__skills-container__title'>
						<FiArrowDownRight className='about__skills__skills-container__title__icon' />
						<span className='about__skills__skills-container__title__text'>
							dev skills & tools.
						</span>
					</h2>
					{devSkillsData.map((item) => {
						return (
							<ProgressBar
								key={item.id}
								icon={item.icon}
								label={item.label}
								percentage={item.percentage}
							/>
						);
					})}
				</div>
			</div>

			<div className='about__skills'>
				<div className='about__skills__skills-container'>
					<h2 className='about__skills__skills-container__title'>
						<FiArrowDownRight className='about__skills__skills-container__title__icon' />
						<span className='about__skills__skills-container__title__text'>
							soft skills.
						</span>
					</h2>
					{softSkillsData.map((item) => {
						return (
							<ProgressBar
								key={item.id}
								icon={item.icon}
								label={item.label}
								percentage={item.percentage}
							/>
						);
					})}
				</div>

				<div className='about__skills__skills-container'>
					<h2 className='about__skills__skills-container__title'>
						<FiArrowDownRight className='about__skills__skills-container__title__icon' />
						<span className='about__skills__skills-container__title__text'>
							languages.
						</span>
					</h2>
					{languagesData.map((item) => {
						return (
							<ProgressBar
								key={item.id}
								icon={item.icon}
								label={item.label}
								percentage={item.percentage}
							/>
						);
					})}
				</div>
			</div>
		</section>
	);
}
