import React from 'react';
import { Link } from 'react-router-dom';
import './Button.scss';

const Button = ({
	to,
	children,
	size = 'medium',
	outline = false,
	icon,
	iconPosition = 'left',
	styleType = 'default',
	download = false,
}) => {
	const buttonClass = `
    button
    button-${size}
    ${outline ? 'button-outline' : 'button-background'}
    ${styleType === 'pill' ? 'button-pill' : ''}
  `;

	if (download) {
		return (
			<a href={to} className={buttonClass.trim()} download>
				{icon && iconPosition === 'left' && children && (
					<span className='button-icon'>{icon}</span>
				)}
				{children && <span className='button-text'>{children}</span>}
				{icon && iconPosition === 'right' && children && (
					<span className='button-icon button-icon-right'>
						{icon}
					</span>
				)}
				{!children && icon && (
					<span className='button-icon-only'>{icon}</span>
				)}
			</a>
		);
	}

	return (
		<Link to={to} className={buttonClass.trim()}>
			{icon && iconPosition === 'left' && children && (
				<span className='button-icon'>{icon}</span>
			)}
			{children && <span className='button-text'>{children}</span>}
			{icon && iconPosition === 'right' && children && (
				<span className='button-icon button-icon-right'>{icon}</span>
			)}
			{!children && icon && (
				<span className='button-icon-only'>{icon}</span>
			)}
		</Link>
	);
};

export default Button;
