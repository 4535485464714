import GoogleLogo from '../../../assets/google-logo.png';
import SngularLogo from '../../../assets/sngular-logo.jpg';
import HabLogo from '../../../assets/hab-logo.jpg';
import CbsLogo from '../../../assets/cbs-logo.png';

export const experienceData = [
	{
		id: 1,
		img: SngularLogo,
		alt: 'Sngular Logo',
		date: 'August 2021 - February 2023 | 1 year 6 months',
		type: 'Job',
		title: 'Junior Frontend Developer & UI Designer',
		company: 'Corunet (SNGULAR)',
		location: 'Remote / A Coruña',
		description:
			"I've participated in a few different projects using React and Figma. From creating a design system on StoryBook or developing a data management app for a retail company, to designing de UI of a sales data visualization website.",
	},
	{
		id: 2,
		img: CbsLogo,
		alt: 'Cartel Crazy Burrito Shack Logo',
		date: 'December 2015 - December 2019 | 4 years',
		type: 'Job',
		title: 'Waiter & Kitchen Assistant',
		company: 'Cartel - Crazy Burrito Shack',
		location: 'Zürich',
		description:
			'Before starting in the technology world, I worked for several years as a waiter and kitchen assistant in different places and countries. This experience helped me to develop communication skills, work under pressure, organization, and teamwork.',
	},
];

export const trainingData = [
	{
		id: 1,
		img: GoogleLogo,
		alt: 'Google Logo',
		date: 'July 2023 - February 2024 | 8 months',
		type: 'School',
		title: 'Google UX Design Professional Certificate',
		company: 'Google',
		location: 'Remote',
		description:
			"I completed the 'Google UX Design Professional Certificate,' where I learned how to properly execute the entire design process of a digital product, from the UX research, where I could empathize and conduct user interviews, to UI design and app prototyping.",
	},

	{
		id: 2,
		img: HabLogo,
		alt: 'Hack a Boss Logo',
		date: 'October 2020 - March 2021 | 6 months',
		type: 'School',
		title: 'Full Stack Web Development Bootcamp',
		company: 'Hack a Boss',
		location: 'Remote',
		description:
			'I completed the Full-Stack web development bootcamp at Hack a Boss, where I learned to use tools like HTML, CSS, JavaScript, Node.js, MySQL, and React through the complete development of a second-hand sales application.',
	},
];
