import { useEffect, useState } from 'react';
import { Loader } from '../../components/Loader/Loader';
import DividerImg from '../../assets/divider.png';
import { contactData } from './data/data';

import './Contact.scss';
import { Link } from 'react-router-dom';
import PageIntro from '../../components/PageIntro/PageIntro';

export default function Contact() {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1500);
	}, []);

	return loading ? (
		<Loader />
	) : (
		<section className='contact' id='contact'>
			<PageIntro
				title='contact.'
				description="Have questions, ideas, or just want to say hello? I'm always open to new opportunities and collaborations. Drop me a line and let's connect!"
			/>

			<div className='contact__links'>
				{contactData.map((item) => {
					return (
						<Link
							key={item.id}
							to={item.path}
							target='_blank'
							className='contact__links__card'
						>
							<img
								src={item.logo}
								alt={item.alt}
								className='contact__links__card__logo'
							/>
							<h4 className='contact__links__card__title'>
								{item.title}
							</h4>
							<p className='contact__links__card__subtitle'>
								{item.subtitle}
							</p>
						</Link>
					);
				})}
			</div>
		</section>
	);
}
