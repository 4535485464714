import { useEffect, useState } from 'react';
import { Loader } from '../../components/Loader/Loader';
import Button from '../../components/Button/Button';
import Marquee from '../../components/Marquee/Marquee';

import { GrMail } from 'react-icons/gr';
import { FaLinkedin } from 'react-icons/fa';
import { IoChatbubblesOutline } from 'react-icons/io5';
import { MdOutlineFileDownload } from 'react-icons/md';

import FigmaLogo from '../../assets/figma-logo.svg';
import HtmlLogo from '../../assets/html-logo.svg';
import CssLogo from '../../assets/css-logo.svg';
import ReactLogo from '../../assets/react-logo.svg';
import StorybookLogo from '../../assets/storybook-logo.svg';
import JsLogo from '../../assets/js-logo.svg';
import TsLogo from '../../assets/ts-logo.svg';
import VscodeLogo from '../../assets/vscode-logo.svg';
import GitLogo from '../../assets/git-logo.svg';
import NodeLogo from '../../assets/node-logo.svg';
import SqlLogo from '../../assets/mysql-logo.svg';

import MessageIcon3d from '../../assets/connect.png';

import './Home.scss';

export default function Home() {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1500);
	}, []);

	return loading ? (
		<Loader />
	) : (
		<section className='home' id='home'>
			<div className='home__header'>
				<h4 className='home__header__item'>ux designer.</h4>
				<h4 className='home__header__item'>ui designer.</h4>
				<h4 className='home__header__item'>frontend dev.</h4>
			</div>
			<div className='home__intro'>
				<h1 className='home__intro__title'>hey there!</h1>
				<h1 className='home__intro__subtitle'>
					my name is {''}
					<span className='home__intro__subtitle__outlined'>
						guill
						<span className='home__intro__subtitle__outlined__reverse'>
							e
						</span>
					</span>
				</h1>
				<h5 className='home__intro__description'>
					and I love designing and prototyping solutions that simplify
					user tasks, blending creativity and technology to create
					digital products that are visually appealing, functional,
					and easy to use.
				</h5>
			</div>

			<div className='home__buttons'>
				<Button size='large' to='/projects'>
					view projects.
				</Button>
				<Button
					size='large'
					outline
					icon={<MdOutlineFileDownload />}
					to='/CV-GuillermoGilVazquez.pdf'
					download
				>
					download cv.
				</Button>
			</div>

			<Marquee>
				<img src={FigmaLogo} alt='Figma Logo' />
				<img src={HtmlLogo} alt='Html Logo' />
				<img src={CssLogo} alt='Css Logo' />
				<img src={ReactLogo} alt='React Logo' />
				<img src={StorybookLogo} alt='Storybook Logo' />
				<img src={JsLogo} alt='Javascript Logo' />
				<img src={TsLogo} alt='Typescript Logo' />
				<img src={VscodeLogo} alt='Visual Studio Code Logo' />
				<img src={GitLogo} alt='Git Logo' />
				<img src={NodeLogo} alt='Node JS Logo' />
				<img src={SqlLogo} alt='MySQL Logo' />
			</Marquee>

			<div className='home__contact'>
				<div className='home__contact__content'>
					<h1 className='home__contact__content__title'>
						let's connect!
					</h1>

					<div className='home__contact__content__links'>
						<a
							href='https://www.linkedin.com/in/guillermo-gil-vazquez-link'
							target='_blank'
							rel='noreferrer'
							className='home__contact__content__links__link'
						>
							<FaLinkedin />
							<span>Guillermo Gil Vázquez</span>
						</a>

						<a
							href='mailto:guillx.design@gmail.com'
							target='_blank'
							rel='noreferrer'
							className='home__contact__content__links__link'
						>
							<GrMail />
							<span>guillx.design@gmail.com</span>
						</a>
					</div>

					<div className='home__contact__content__text'>
						Are you looking for a designer with frontend development
						skills to join your team? You're in the right place. Just
						send me an email, a WhatsApp, or a message on LinkedIn,
						and I'll get back to you as soon as possible.
					</div>

					<Button
						size='large'
						to='/contact'
						icon={<IoChatbubblesOutline />}
					>
						get in touch.
					</Button>
				</div>
				<img
					src={MessageIcon3d}
					alt=''
					className='home__contact__3dicon'
				/>
			</div>
		</section>
	);
}
