import './Loader.scss';
import { Triangle } from 'react-loader-spinner';

export const Loader = () => {
	return (
		<div className='loader'>
			<Triangle
				visible={true}
				height='70'
				width='70'
				color='#c38af7'
				ariaLabel='triangle-loading'
				wrapperStyle={{}}
				wrapperClass=''
			/>
			<h3 className='loader__text'>Loading...</h3>
		</div>
	);
};
