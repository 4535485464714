import { SiCanva } from 'react-icons/si';
import { IoLogoJavascript, IoCameraOutline } from 'react-icons/io5';
import { RiUserSearchLine } from 'react-icons/ri';

import {
	FaCss3Alt,
	FaFigma,
	FaHtml5,
	FaPaintBrush,
	FaReact,
	FaWordpressSimple,
} from 'react-icons/fa';

export const designSkillsData = [
	{
		label: 'Figma',
		percentage: '75%',
		icon: <FaFigma />,
	},
	{
		label: 'Canva',
		percentage: '50%',
		icon: <SiCanva />,
	},
	{
		label: 'UI Design',
		percentage: '75%',
		icon: <FaPaintBrush />,
	},
	{
		label: 'UX Research',
		percentage: '60%',
		icon: <RiUserSearchLine />,
	},
];

export const devSkillsData = [
	{
		label: 'HTML',
		percentage: '80%',
		icon: <FaHtml5 />,
	},
	{
		label: 'CSS',
		percentage: '70%',
		icon: <FaCss3Alt />,
	},
	{
		label: 'React JS',
		percentage: '50%',
		icon: <FaReact />,
	},
	{
		label: 'JavaScript',
		percentage: '50%',
		icon: <IoLogoJavascript />,
	},
];

export const softSkillsData = [
	{
		label: 'Empathy',
		percentage: '90%',
	},
	{
		label: 'Attention to Detail',
		percentage: '90%',
	},
	{
		label: 'Creativity',
		percentage: '80%',
	},
	{
		label: 'Critical Thinking',
		percentage: '80%',
	},
];

export const languagesData = [
	{
		label: 'Galician',
		percentage: '100%',
	},
	{
		label: 'Spanish',
		percentage: '100%',
	},
	{
		label: 'English',
		percentage: '70%',
	},
	{
		label: 'Italian',
		percentage: '30%',
	},
];
