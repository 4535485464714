import './Footer.scss';
import { GrMail } from 'react-icons/gr';
import { FaWhatsapp } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { FaBehanceSquare } from 'react-icons/fa';
import { FaGithubAlt } from 'react-icons/fa6';

const Footer = () => {
	return (
		<div className='footer'>
			<p className='footer__copyright'>
				© 2024 <span>Guillx Design</span> | ALL RIGHTS RESERVED
			</p>

			<div className='footer__links'>
				<a
					href='mailto:guillx.design@gmail.com'
					target='_blank'
					rel='noreferrer'
					className='footer__links__link'
				>
					<GrMail />
					<span>Mail</span>
				</a>
				<a
					href='https://www.linkedin.com/in/guillermo-gil-vazquez-link'
					target='_blank'
					rel='noreferrer'
					className='footer__links__link'
				>
					<FaLinkedin />
					<span>Linkedin</span>
				</a>
				<a
					href='https://api.whatsapp.com/send?phone=34625440474'
					target='_blank'
					rel='noreferrer'
					className='footer__links__link'
				>
					<FaWhatsapp />
					<span>Whatsapp</span>
				</a>
				<a
					href='https://www.behance.net/guillxgv'
					target='_blank'
					rel='noreferrer'
					className='footer__links__link'
				>
					<FaBehanceSquare />
					<span>Bëhance</span>
				</a>
				<a
					href='https://github.com/Guillx'
					target='_blank'
					rel='noreferrer'
					className='footer__links__link'
				>
					<FaGithubAlt />
					<span>Github</span>
				</a>
			</div>

			<div className='footer__slogan'>
				'I think that needs a bit of glitter'
			</div>
		</div>
	);
};

export default Footer;
