import DividerImg from '../../assets/divider.png';
import './PageIntro.scss';

export default function PageIntro({ title, description }) {
	return (
		<div className='page-intro'>
			<h1 className='page-intro__title'>{title}</h1>
			<p className='page-intro__description'>{description}</p>
			<img
				src={DividerImg}
				alt='Divider'
				className='page-intro__divider'
			/>
		</div>
	);
}
