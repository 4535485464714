import './Marquee.scss';

const Marquee = ({ children }) => {
	return (
		<div className='marquee'>
			<div className='marquee-content'>
				{children}
				{children}
			</div>
		</div>
	);
};

export default Marquee;
