import { useEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation,
} from 'react-router-dom';
import './App.scss';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Footer from './components/Footer/Footer';
import About from './pages/About/About';
import Experience from './pages/Experience/Experience';
import Projects from './pages/Projects/Projects';
import Contact from './pages/Contact/Contact';
import DividerImg from './assets/divider.png';
import { Loader } from './components/Loader/Loader';

function App() {
	const location = useLocation();
	const [loading, setLoading] = useState(true);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1500);
	}, []);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768); // Puedes ajustar este valor según tus necesidades
		};

		handleResize(); // Verificar el tamaño inicial

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return loading ? (
		<Loader />
	) : (
		<>
			<Navbar />

			<Routes location={location} key={location.pathname}>
				<Route index element={<Home />} />
				<Route path='/about' element={<About />} />
				<Route path='/experience' element={<Experience />} />
				<Route path='/projects' element={<Projects />} />
				<Route path='/contact' element={<Contact />} />
			</Routes>

			<Footer />
		</>
	);
}

export default App;
